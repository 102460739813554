import React from "react";
import ImageSvgs from "../ImageSvgs";
import Avatar from "react-avatar";
import { useNavigate } from "react-router";
import { getBaseUrl } from "../../config/environment";
import CustomTooltip from "./CustomTooltip";

const ExpertsComponent = ({ data, owner }) => {
  const expert = data;
  const id =
    window.location.href.toLowerCase().includes("dlayer") ||
    window.location.href.toLowerCase().includes("preview")
      ? expert.attributes["employee-id"]
      : expert.id;

  const role =
    window.location.href.includes("topic") && owner === false
      ? "Deputy"
      : window.location.href.includes("topic") && owner === true
      ? "Owner"
      : window.location.href.includes("applications") ||
        window.location.href.includes("process_id") ||
        window.location.href.includes("employee_relation") ||
        window.location.href.includes("organization") ||
        window.location.href.includes("communities")
      ? owner
        ? "Owner"
        : "Deputy"
      : window.location.href.includes("trainings")
      ? "Trainer"
      : window.location.href.includes("tools")
      ? "Power User"
      : "";

  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column experts-comp">
      <div className="d-flex flex-column mt-1">
        <span className="d-flex flex-row">
          <div className="me-1 mt-1">
            {expert?.attributes["profile-picture"] ? (
              <CustomTooltip
                name={expert.attributes["preferred-name"]}
                orgCode={
                  expert.attributes["employee-org-master"].data.attributes[
                    "org-title"
                  ]
                }
              >
                <img
                  className="mt-1 user-profile-img"
                  src={`${getBaseUrl()}${
                    expert?.attributes["profile-picture"]
                  }`}
                  alt="User"
                />
              </CustomTooltip>
            ) : (
              <CustomTooltip
                name={expert.attributes["preferred-name"]}
                orgCode={
                  expert.attributes["employee-org-master"].data.attributes[
                    "org-title"
                  ]
                }
              >
                <Avatar
                  name={expert.attributes["preferred-name"]}
                  size="40"
                  round={true}
                />
              </CustomTooltip>
            )}
          </div>
          <div style={{ width: "100%" }}>
            <p
              data-toggle="tooltip"
              data-placement="top"
              title={expert.attributes["preferred-name"]}
              className="mb-0"
            >
              {expert.attributes["preferred-name"]}
            </p>
            {expert.attributes["employee-org-master"] ? (
              <p
                data-toggle="tooltip"
                data-placement="top"
                title={expert.attributes["preferred-name"]}
                className="mb-0 fs-6"
                style={{
                  color: "#8a00e5",
                  width: "75%",
                  fontSize: "14px !important",
                  fontWeight: "100",
                }}
              >
                {
                  expert.attributes["employee-org-master"].data.attributes[
                    "org-title"
                  ]
                }
              </p>
            ) : null}
          </div>
        </span>
        <span className="d-flex ms-1">
          {" "}
          <h7 className="mb-0 text-muted pt-1" style={{ fontSize: "14px" }}>
            {role && role}
          </h7>
          <span className="ms-1">
            {" "}
            <span
              className="img ms-1 me-2 copy-icon"
              data-toggle="tooltip"
              data-placement="top"
              title="Copy email"
              onClick={() =>
                navigator.clipboard.writeText(expert?.attributes["email-id"])
              }
            >
              <ImageSvgs name="expertCopy" />
            </span>
            <a
              className="me-2"
              href={`mailto:${expert?.attributes["email-id"]}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Mail to"
            >
              <ImageSvgs name="expertMail" />
            </a>
            <span
              className="img ms-1 me-2 copy-icon"
              data-toggle="tooltip"
              data-placement="top"
              title="User relation in FIN GS"
              onClick={() => {
                navigate(`/employee_relation?id=${id.toString()}`);
              }}
            >
              <ImageSvgs name="relationIcon" />
            </span>
          </span>
        </span>
      </div>
    </div>
  );
};

export default ExpertsComponent;
